<template>
  <div class="app">
    <AppHeader fixed id="wisdom-header">
      <b-navbar style="height: 50px; width: 100%;">
        <SidebarToggler class="d-flex d-lg-none d-xl-none" mobile />
        <b-link class="navbar-brand" to="" v-if="!device.isMobile">
          <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="WisdomEra">
          <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="WisdomEra">
        </b-link>
        <SidebarToggler class="d-none d-lg-flex d-xl-flex" />
        <b-navbar-nav class="d-none d-lg-flex d-xl-flex mr-auto">
          <!-- <b-nav-item class="px-2" to="/dashboard/search-patient" :exact="true"> -->
          <!-- <b-nav-item v-if="user" class="px-2" to="/dashboard" :exact="true">{{ $t('wdm16.4112') }}</b-nav-item> -->
          <!-- <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item> -->
          <!-- <b-nav-item v-if="user" class="px-2" to="/settings/user" :exact="true">{{ $t('wdm16.4037') }}</b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <!-- <img src="@/icon/1994869.png" class="header-icon" /> -->
          <!-- <strong style="color: #d400ff;"> <i>Wisdomera</i> </strong> -->
          <strong v-if="w_presentation" style="color: red;">SUNUM MODU AÇIK</strong>
          <!-- <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <img src="@/icon/1545359_red.png" class="header-icon" title="COVID-19" />
            </template>
            <template slot="dropdown">
              <b-dropdown-header tag="div" class="text-center"><strong>Hızlı erişim</strong></b-dropdown-header>
            </template>
          </AppHeaderDropdown> -->
          <b-nav-item id="help-info" v-if="!help.show && user" class="d-md-down-none" @click="store.commit('help_status', true)">
            <i class="fa fa-question" style="color: green;"></i>
          </b-nav-item>
          <b-nav-item v-if="help.show && user" id="help-info" v-else class="d-md-down-none" @click="store.commit('help_status', false)">
            <i class="fa fa-question" style="color: red;"></i>
          </b-nav-item>
          <b-popover v-if="user" target="help-info" triggers="hover focus" placement="bottom">
            <template slot="title"></template>
            <b-row>
              <b-col cols="12">Sayfalardaki yardım iconlarının görünmesini & kapanmasını sağlar</b-col>
            </b-row>
          </b-popover>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://fb.me/WisdomEra" target="_target" style="margin-right: 10px;"><i class="fa fa-facebook"> </i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://twitter.com/WisdomEra" target="_target" style="margin-right: 10px;"><i class="fa fa-twitter"></i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://instagram.com/wisdomera" target="_target" style="margin-right: 10px;"><i class="fa fa-instagram"></i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://www.linkedin.com/company/wisdomera/" target="_target" style="margin-right: 10px;"><i class="fa fa-linkedin"></i></a>
          </b-nav-item>
          <!--         <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item> -->
          <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <i class="icon-magic-wand"></i>
            </template>
            <template slot="dropdown">
              <!--               <b-dropdown-header tag="div" class="text-center"><strong>Hızlı erişim</strong></b-dropdown-header>
              <b-dropdown-item @click="go_to_home()"><i class="fa fa-bell-o" /> Anasayfa
                <b-badge variant="info">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user" @click="go_to_messenger_page()"><i class="fa fa-comments-o" /> Messenger & Meeting
                <b-badge variant="danger">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="!help.show" @click="store.commit('help_status', true)"><i class="fa fa-comments" /> Yardım iconlarını göster
                <b-badge variant="success">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="help.show" @click="store.commit('help_status', false)"><i class="fa fa-comments" /> Yardım iconlarını gösterme
                <b-badge variant="danger">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user && user.permissions_result && user.permissions_result.indexOf('general_open-test-model') !== -1" @click="store.commit('TestMode')"><i class="fa fa-comments" /> Test Modu {{ StoreTest.mode ? ' (AÇIK) ' :' (KAPALI) ' }}
                <b-badge :variant="StoreTest.mode ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user && user.permissions_result && user.permissions_result.indexOf('administrator') !== -1" @click="f_goToCustomerSupport()"><i class="fa fa-user" /> Müşteri Desteği
                <b-badge :variant="StoreTest.mode ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item> -->
              <b-dropdown-item @click="f_changePresentationMode()"><i class="fa fa-comments" /> Sunum Modu {{ w_presentation ? ' (AÇIK) ' :' (KAPALI) ' }}
                <b-badge :variant="w_presentation ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item>
            </template>
          </AppHeaderDropdown>
          <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <i class="icon-people"></i>
            </template>
            <template slot="dropdown">
              <b-dropdown-header tag="div" class="text-center"><strong>Hesaplar</strong></b-dropdown-header>
              <b-dropdown-item v-for="(account, account_ind) in account_list" :key="'account_list_' + account_ind" @click="changeMultiAccount(account)">
                <i v-if="account.main_account === 1" class="fa fa-id-card-o"></i>
                <i v-else class="fa fa-user-o"></i>
                <template v-if="user && user.username === account.username">
                  <strong class="text-success">{{ account.name ? account.name : account.username }}</strong>
                </template>
                <template v-else>
                  {{ account.name ? account.name : account.username }}
                </template>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item v-if="account_list.length > 0 && account_list[0].special_permission && account_list[0].special_permission.indexOf('wisdom_login-to-users-without-password') !== -1" @click="changeAccountWithoutPasswordModal = true">Hesaplara Şifresiz Geçiş (özel yetki)</b-dropdown-item>
            </template>
          </AppHeaderDropdown>
          <AppHeaderDropdown right no-caret>
            <template slot="header">
              <i :class="['flag-icon', d_activeLanguage === 'en' ? 'flag-icon-us' : d_activeLanguage === 'fa' ? 'flag-icon-ir' : 'flag-icon-' + d_activeLanguage]" :title="d_activeLanguage" :id="d_activeLanguage"></i>
            </template>
            <template slot="dropdown">
              <template v-if="d_wisdomEraSystem && d_wisdomEraSystem.languages">
                <template v-for="(lang, lang_ind) in d_wisdomEraSystem.languages">
                  <b-dropdown-item @click="f_saveLanguageChange(lang.value)"><i :class="['flag-icon', lang.value === 'en' ? 'flag-icon-us' : lang.value === 'fa' ? 'flag-icon-ir' : 'flag-icon-' + lang.value]" :title="lang.value" :id="lang.value"></i>{{ lang.label }}
                  </b-dropdown-item>
                </template>
              </template>
            </template>
          </AppHeaderDropdown>
          <b-nav-item class="d-md-down-none" @click="d_showWisdomeraApps = true;" title="Uygulamalar">
            <img src="@/icon/2751062.png" style="width: 3em; padding: 0px;" />
          </b-nav-item>
          <!-- <DefaultHeaderDropdownAccnt /> -->
        </b-navbar-nav>
        <!-- <AsideToggler class="d-none d-lg-block" /> -->
        <!--<AsideToggler class="d-lg-none" mobile />-->
      </b-navbar>
    </AppHeader>
    <div class="app-body" style="margin-bottom: 0px;">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="navItems"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <!-- <Breadcrumb :list="list" /> -->
        <div id="router-div">
          <router-view></router-view>
        </div>
      </main>
      <!--       <AppAside fixed>
        <DefaultAside />
      </AppAside> -->
    </div>
    <TheFooter :fixed="true" style="align-items: initial; background: #2f353a; height: 30px;" id="wisdom-footer">
      <!--footer-->
      <div style="float: left;">
        <a href="http://wisdomera.io" style="color: #3cbdc2;">
          WisdomEra {{'v'}}
          <span v-if="st_version && st_version.data && st_version.data.version">
            {{ st_version.data.version }}
          </span> {{ 'OLK'}}
        </a>
        <span class="ml-1" style="color: #3cbdc2;">&copy; 2017.</span>
        <span style="color: #3cbdc2;">WisdomEra Inc. &nbsp</span>
      </div>
      <div style="float: left;">
        <!-- <wisdom-socket></wisdom-socket> -->
      </div>
      <div style="float: left;">
        <!-- <task-list></task-list> -->
      </div>
      <div class="ml-auto">
        <!--         <span class="mr-1">Powered by</span>
        <a href="https://wisdomera.io">WisdomEra</a> 
 -->
      </div>
    </TheFooter>
    <div v-if="d_showRightMenu && user" class="left-page-close-button">
      <b-button @click="d_showRightMenu = false" size="lg" style="padding: 0px;" variant="secondary"><img src="@/icon/1890926.png" width="30px;" /></b-button>
    </div>
    <div v-if="d_showRightMenu && user" class="left-page-fullscreen-button">
      <b-button variant="secondary" @click="f_goToAllTasksForAllOrganizations()" size="lg" style="padding: 0px;">
        <img src="@/icon/1568887.png" width="30px;" />
      </b-button>
    </div>
    <div class="wisdom-right-menu" v-if="d_showRightMenu && user" @mouseleave="">
      <task-list :right_menu="true"></task-list>
    </div>
    <!-- <modal v-if="d_showMessenger" @close="d_showMessenger = false" :width="'1500'">
      <h3 slot="header"> <img src="@/icon/1519119.png" style="width: 2em;" /> WisdomEra Mesajlaşma & Toplantı </h3>
      <div slot="body">
        <messenger></messenger>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showMessenger = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal> -->
    <b-modal id="d_showChangeMyHospitalModal" v-model="d_showChangeMyHospitalModal" title="Hastanemi Değiştir (özel yetki)" centered header-bg-variant="info" header-text-variant="dark">
      <b-row>
        <b-col cols="6">
          <b-form-select :plain="true" v-model="d_changeHospitalData.hospital_group" @change="f_changeHospitalGroup()" required>
            <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
          </b-form-select>
        </b-col>
        <b-col cols="6">
          <b-form-select :plain="true" v-model="d_changeHospitalData.hospital_id" required>
            <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
          </b-form-select>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <button :disabled="!d_changeHospitalData.hospital_group || !d_changeHospitalData.hospital_id" type="button" class="btn btn-success" @click="f_changeMyHospital()">Hastanemi Değiştir</button>
        <button type="button" class="btn btn-danger" @click="d_showChangeMyHospitalModal = false">{{ $t('wdm16.4053') }}</button>
      </template>
    </b-modal>
    <modal v-if="help.no" @close="store.commit('help_no', '')" :width="'1500'">
      <h3 slot="header"> Yardım</h3>
      <div slot="body">
        <help :help_no="help.no"></help>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="store.commit('help_no', '')">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="store-loading" v-model="ShowLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -2px -2px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="1">
          <!-- <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
          <img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" />
        </b-col>
        <b-col sm="12" lg="11">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="show_store_modal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'">
      <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="show_store_modal = false">{{ $t('wdm16.11212') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="show_store_modal = false">{{ $t('wdm16.3975') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="ConfirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
    <b-modal id="d_showAgreementModal.show" v-model="d_showAgreementModal.show" title="Sözleşme ve Lisans Kabul İşlemleri" centered hide-footer no-close-on-esc centered no-stacking no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark">
      <agreement-modal :p_showThisModal="d_showAgreementModal" :p_missingAgreement="d_missingAgreement"></agreement-modal>
    </b-modal>
    <b-modal v-if="d_showWisdomeraApps" v-model="d_showWisdomeraApps" size="sm" title="Uygulamalar" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <wisdomera-apps></wisdomera-apps>
    </b-modal>
  </div>
</template>

<script>
import _nav from '@/_nav';
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@/components/Sidebar'
import { Header as AppHeader, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
/*import {
  default as WisdomSocket
} from '@/components/socket/index';
*/import store from '@/store';
// import WisdomDataService from "@/services/wisdom_data";

export default {
  name: 'DefaultContainer',
  components: {
    AppAside,
    AppHeader,
    AppHeaderDropdown,
    AppSidebar,
    AsideToggler,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    Modal,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    SidebarToggler,
    TheFooter
  },
  computed: {
    ...mapGetters({
      device: 'device',
      StoreLoading: 'loading'
    })
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  created: function () {},
  beforeCreate: function () {},
  mounted: function () {},
  data () {
    return {}
  },
  methods: {},
  watch: {}
}

</script>

<style type="text/css">
.clb-value {
  color: red;
  font-weight: bold;
}

.cub-value {
  color: red;
  font-weight: bold;
}

.lb-value {
  color: red;
  font-weight: bold;
}

.ub-value {
  color: red;
  font-weight: bold;
}

.right-footer-icon {
  width: 2em;
  cursor: pointer;
  float: right;
  margin-right: 5px;
}

.right-footer-icon:hover {
  width: 2.2em;
  cursor: pointer;
}

.header-icon {
  width: 2.5em;
  cursor: pointer;
  float: right;
}

.header-icon:hover {
  width: 3em;
  cursor: pointer;
}

.left-page-close-button {
  position: fixed;
  right: 0px;
  top: 55px;
  z-index: 1101;
}

.left-page-fullscreen-button {
  position: fixed;
  right: 35px;
  top: 55px;
  z-index: 1101;
}

</style>

